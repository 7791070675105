import React from "react"
import Helmet from "react-helmet"

import PageLink from "../components/pageLink"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./awards.scss"
import { onBackToMain, BASE_TRANSITION_LENGTH } from "../transitions"

const AwardsPage = ({ transitionStatus }) => (
  <>
    <Helmet
      bodyAttributes={{
        class: "page awards",
      }}
      key="helmet"
    />
    ,
    <Layout transitionStatus={transitionStatus} page="awards">
      <SEO title="Awards" />
      <PageLink
        to="/"
        className="transitionLink transitionLink--home"
        exit={{ length: BASE_TRANSITION_LENGTH }}
        entry={{
          length: BASE_TRANSITION_LENGTH,
          trigger: args => onBackToMain("awards", args),
        }}
      ></PageLink>
    </Layout>
    ,
  </>
)

export default AwardsPage
